import React, { useState, useCallback } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import styled from 'styled-components';
import { Form, Spin } from 'antd';
import PropTypes from 'prop-types';
import 'draft-js/dist/Draft.css';

const FormItem = Form.Item;

const EditorWrapper = styled.div`
  .DraftEditor-root {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
    min-height: 300px;
    background: white;
  }

  .DraftEditor-editorContainer {
    min-height: 280px;
  }

  .toolbar {
    margin-bottom: 8px;
    padding: 8px;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .toolbar button {
    padding: 4px 8px;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    &.active {
      background: #1890ff;
      color: white;
      border-color: #1890ff;
    }
  }
`;

const CustomRichEditor = props => {
  const { 
    label, 
    name, 
    disabled, 
    rules, 
    initialValue, 
    changeHandler, 
    style, 
    offset = 1 
  } = props;

  const [loaded, setLoaded] = useState(false);

  // Convert HTML string to Draft.js content state
  const getInitialState = () => {
    if (initialValue) {
      const blocksFromHTML = convertFromHTML(initialValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [editorState, setEditorState] = useState(getInitialState());

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }, []);

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (changeHandler) {
      const html = stateToHTML(newEditorState.getCurrentContent());
      // Create an object similar to CKEditor's change event to maintain compatibility
      const compatEvent = {
        editor: {
          getData: () => html
        }
      };
      changeHandler(compatEvent);
    }
  };

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <EditorWrapper style={style}>
      <FormItem
        name={name}
        rules={rules}
        label={label}
        labelCol={{ span: 24 }}
        disabled={disabled}
        wrapperCol={{ span: 24, offset }}
      >
        <div>
          <div className="toolbar">
            <button
              type="button"
              className={currentInlineStyle.has('BOLD') ? 'active' : ''}
              onClick={() => toggleInlineStyle('BOLD')}
            >
              Bold
            </button>
            <button
              type="button"
              className={currentInlineStyle.has('ITALIC') ? 'active' : ''}
              onClick={() => toggleInlineStyle('ITALIC')}
            >
              Italic
            </button>
            <button
              type="button"
              className={currentInlineStyle.has('UNDERLINE') ? 'active' : ''}
              onClick={() => toggleInlineStyle('UNDERLINE')}
            >
              Underline
            </button>
            <button
              type="button"
              className={blockType === 'header-one' ? 'active' : ''}
              onClick={() => toggleBlockType('header-one')}
            >
              H1
            </button>
            <button
              type="button"
              className={blockType === 'header-two' ? 'active' : ''}
              onClick={() => toggleBlockType('header-two')}
            >
              H2
            </button>
            <button
              type="button"
              className={blockType === 'unordered-list-item' ? 'active' : ''}
              onClick={() => toggleBlockType('unordered-list-item')}
            >
              UL
            </button>
            <button
              type="button"
              className={blockType === 'ordered-list-item' ? 'active' : ''}
              onClick={() => toggleBlockType('ordered-list-item')}
            >
              OL
            </button>
          </div>
          <Editor
            editorState={editorState}
            onChange={handleEditorChange}
            handleKeyCommand={handleKeyCommand}
            placeholder="Enter your content here..."
            readOnly={disabled}
          />
        </div>
      </FormItem>
      {!loaded && (
        <div style={{ height: '40vh', display: 'grid' }} align="center">
          <Spin style={{ margin: 'auto' }} />
        </div>
      )}
    </EditorWrapper>
  );
};

CustomRichEditor.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  rules: PropTypes.array,
  initialValue: PropTypes.string,
  changeHandler: PropTypes.func,
  style: PropTypes.object,
  offset: PropTypes.number
};

export default CustomRichEditor; 