import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Spin, Form } from 'antd';
import { withHTMLFieldValue } from '../../look';

const FormItem = styled(Form.Item)`
  .ant-col-offset-1 {
    margin-left: 0px !important
  }
`;

const TinyMCESurveyField = props => {
  const [loaded, setLoaded] = useState(false);

  const { 
    label, 
    name, 
    disabled, 
    rules, 
    initialValue, 
    onChange, 
    style, 
    height, 
    width, 
    onBlur,
    ...rest 
  } = props;

  // Handle TinyMCE initialization
  const handleEditorInit = (evt, editor) => {
    setLoaded(true);
  };

  // Handle content change
  const handleEditorChange = (content, editor) => {
    if (onChange) {
      // Create an object similar to CKEditor's change event to maintain compatibility
      const compatEvent = {
        editor: {
          getData: () => content
        }
      };
      onChange(compatEvent);
    }
  };

  return (
    <div style={style}>
      <FormItem {...rest}>
        <div className='survey-tinymce-view'>
          <Editor
            apiKey='glrsw4c2xy02wjbp97m335bjq5c58vvtvjqqh7xnew6gpeuu'

            className="one-on-one-custom-ck"
            disabled={disabled}
            initialValue={withHTMLFieldValue(initialValue)}
            onInit={handleEditorInit}
            onEditorChange={handleEditorChange}
            onBlur={onBlur ? () => onBlur() : undefined}
            init={{
              height: height ? height : 80,
              width: width ? width : undefined,
              menubar: false,
              plugins: [
                'lists', 'link', 'autolink'
              ],
              toolbar: 'formatselect | bold italic underline | ' +
                'bullist numlist | link | alignleft aligncenter alignright alignjustify',
              content_style: 'body { font-family:Poppins,Arial,sans-serif; font-size:14px }',
              font_formats: 'Poppins=Poppins,Arial,sans-serif;Arial=arial,helvetica,sans-serif;Times New Roman=times new roman,times;Verdana=verdana,geneva;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Lucida Sans Unicode=lucida sans unicode,lucida grande;Tahoma=tahoma,arial,helvetica,sans-serif;Trebuchet MS=trebuchet ms,geneva',
              content_css: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'
            }}
          />
        </div>
      </FormItem>
      {!loaded && (
        <div style={{ height: '100%' }} align="center">
          <Spin style={{ margin: 'auto' }} />
        </div>
      )}
    </div>
  );
};

TinyMCESurveyField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inFilter: PropTypes.bool,
  meta: PropTypes.object,
  formik: PropTypes.object
};

export default TinyMCESurveyField; 